:root {
  --ae-gap: 5px;
  --ae-text-area-border: 1px solid #ddd;
  --ae-text-area-border-radius: 0;
  --ae-focus-outline-color: #afaeae auto 1px;
  --ae-toolbar-padding: 1px;
  --ae-toolbar-bg-color: #f5f5f5;
  --ae-toolbar-border-radius: 1px solid #ddd;
  --ae-button-bg-color: white;
  --ae-button-border: 1px solid #afaeae;
  --ae-button-radius: 5px;
  --ae-button-hover-bg-color: #f1f1f1;
  --ae-button-active-bg-color: #fffbd3;
  --ae-button-active-hover-bg-color: #fffaad;
  --ae-button-disabled-bg-color: #f5f5f5;
  --ae-picker-label-color: white;
  --ae-picker-icon-bg-color: white;
  --ae-picker-option-bg-color:  #fff;
  --ae-picker-option-active-bg-color: #fffbd3;
  --ae-picker-option-focused-bg-color: #fffaad;
  --ae-picker-option-hover-bg-color: #fbf7ba;
}
